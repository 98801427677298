    header {
        height: 100vh;
        padding-top: 7rem;
        overflow: hidden;
    }
    
    .header__container {
        text-align: center;
        height: 100%;
        position: relative;
    }
    /*============= CTA ==============*/
    
    .cta {
        margin-top: 2.5rem;
        display: flex;
        gap: 1.2rem;
        justify-content: center;
    }
    /*============= Header SOCIALS ==============*/
    
    .header__socials {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        left: 0;
        bottom: 3rem;
    }
    
    .header__socials::after {
        content: '';
        width: 1px;
        height: 2rem;
        background: var(--color-primary);
    }
    /*============= ME IMG ==============*/
    
    .me {
        background: linear-gradient(var(--color-primary), transparent);
        width: 22rem;
        height: 40rem;
        position: absolute;
        left: calc(50% - 11rem);
        margin-top: 1.5rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
    }
    /*============= SCROLL DOWN ==============*/
    
    .scroll__down {
        position: absolute;
        right: -2.3rem;
        bottom: 5rem;
        transform: rotate(90deg);
        font-weight: 300;
        font-size: 1.05rem;
    }
    /* ================== MEDIA QUERIES (MAXIM WIDTH) ============= */
    
     @media screen and (min-width: 1024px) and (min-height: 1000px) {
       .me{ 
        padding-top: 5rem;
       }
    }


    @media screen and (max-width: 1024px) {
        header {
            padding-top: 2rem;
            height: 100vh;
        }
    }

    @media screen and (min-width: 1024px) and (max-height: 700px) {
        .me {
            width: 15rem;
            left: calc(50% - 7.5rem);
         
        }
    }
       @media screen and (min-width: 1024px) and (min-height: 800px) {
        .me {
            width: 18rem;
            left: calc(50% - 9rem);
          
        }
    }
    /* ================== MEDIA QUERIES (MEDIUM DEVICES) ============= */
    
    @media only screen and (max-width: 800px) {
        
        .me {
            width: 17rem;
            left: calc(50% - 8.5rem);
        }
        header .btn {
            font-size: 1.2rem;
        }
    }
    
    @media only screen and (max-width: 400px) {
 
        .me {
            width: 17rem;
            left: calc(50% - 8.5rem);
        }
        header .btn {
            font-size: 1.2rem;
        }
    }
    
    @media only screen and (max-width: 360px) {
        header {
            height: 100vh;
            padding-top: 2rem;
            font-size: 1.2rem;
            overflow: hidden;
        }
        .me {
            width: 13.4rem;
            left: calc(50% - 6.7rem);
        }
        header .btn {
            font-size: 1.2rem;
        }
    }