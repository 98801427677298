#port {
    padding-top: 7rem;
}

.portfolio_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item_img {
    border-radius: 1.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.portfolio_item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio_item_cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.image_source {
    border-radius: 50%;
    height: 275px;
    width: 275px;
}

@media only screen and (max-width: 1224px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media only screen and (max-width: 700px) {
    #port {
        padding-top: 2rem;
    }
    .portfolio_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

@media only screen and (max-width: 300px) {
    #port {
        padding-top: 2rem;
    }
    .portfolio_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .portfolio_item {
        background: var(--color-bg-variant);
        padding: 0.5rem;
        border-radius: 1rem;
        border: 1px solid transparent;
        transition: var(--transition);
    }
    .portfolio_item h3 {
        margin-bottom: 10px;
    }
    .portfolio_item_cta {
        display: block;
        margin-bottom: 1rem;
    }
    .portfolio_item_cta a {
        margin-bottom: 10px;
    }
}